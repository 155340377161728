import * as styles from '@/styles/Services.module.css'

import { Link, graphql, useStaticQuery } from 'gatsby';

import Custom from '@/images/custom.webp'
import Layout from '@/containers/layout'
import SEO from '../../components/SEO';
import { useLocation } from '@reach/router'

const ServicesPage = props => {
  const servicesQueryData = useStaticQuery(graphql`
    query servicePageQuery {
      allMarkdownRemark(filter: {frontmatter: {type: {nin: ["navigation", "general", "customer"]}}}) {
        nodes {
          frontmatter {
            title
            type
            image {
              publicURL
            }
            seo {
              description
              title
              previewImage {
                childImageSharp {
                  original {
                    height
                    width
                  }
                }
                publicURL
              }
            }
          }
        }
      }
    
        allSitePage(sort: {fields: pageContext}) {
          nodes {
            path
            pageContext
          }
        }
      }      
    `)

  const data = servicesQueryData.allMarkdownRemark.nodes.filter(el => el.frontmatter.title === 'Services')[0]
  let allServices = servicesQueryData.allMarkdownRemark.nodes.filter(el => el.frontmatter.type === 'service')

  let seoData = data.frontmatter.seo
  seoData.canonicalURL = useLocation();


  const services = servicesQueryData.allSitePage.nodes.filter(el => el.pageContext.id !== undefined);

  allServices.forEach(service => {

    const sitePageData = services.find(el => el.pageContext.title === service.frontmatter.title);

    service.path = sitePageData.path
  })
  return (
    <div asscroll-container={"true"}>
      <Layout nopad>
        <SEO data={seoData} />
        <div className={styles.services}>
          <h1>Services</h1>
          <div className={styles.grid}>
            {allServices.map(el => (
              <Link key={el.frontmatter?.title} className={styles.serviceCard} to={`${el.path}`}>
                <img src={el.frontmatter?.image?.publicURL} alt={el.frontmatter?.title} />
                <p>{el.frontmatter?.title}</p>
              </Link>)
            )}
            {services.length % 2 !== 0 && <Link className={styles.serviceCard} to={`/contact`}>
              <img src={Custom} alt="Custom" />
              <p>Other Options</p>
            </Link>}
          </div>
        </div>
      </Layout >
    </div>
  )
}

export default ServicesPage